var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit2',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.id ? _vm.data.address : "Новый объект")+" "),(_vm.id)?_c('small',[_vm._v(" ("+_vm._s(_vm.id)+")")]):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('a-btn-save',{staticClass:"pr-2",attrs:{"disabled":_vm.readonly},on:{"click":function($event){return _vm.submit()}}}),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[_c('a-form-modelCols',{staticClass:"pb-3",attrs:{"model":_vm.getFormModel([
          'credit_sum',
          'data.investor_share',
          'plan_sell_price',
          'plan_expenses',
          'credit_first_payment',
          'plan_org_expenses',
          'data.donor_id',
          'data.is_procuratory',
          'data.is_agreement_investor',
          'data.is_agreement_donor',
          'plan_date_finish',
        ]),"values":_vm.data,"errors":_vm.errors,"config":{ dense: true, readonly: _vm.readonly, hideDetails: 'auto' }},on:{"validate":function($event){return _vm.validate($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1),_c('remove-dialog',{attrs:{"title":_vm.removeTitle},on:{"remove":function($event){return _vm._remove(_vm.api, _vm.id)}},model:{value:(_vm.removeDialogShow),callback:function ($$v) {_vm.removeDialogShow=$$v},expression:"removeDialogShow"}}),(_vm.getAccess('suData'))?_c('su-Data',{ref:"suEdit",attrs:{"api":_vm.api,"id":_vm.id,"activate":_vm.value,"dataEdit":_vm.data},on:{"show":function($event){_vm.suEditShow = true}},model:{value:(_vm.suEditShow),callback:function ($$v) {_vm.suEditShow=$$v},expression:"suEditShow"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }