<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ id ? data.address : "Новый объект" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <a-form-modelCols
        v-model="data"
        class="pb-3"
        :model="
          getFormModel([
            'credit_sum',
            'data.investor_share',
            'plan_sell_price',
            'plan_expenses',
            'credit_first_payment',
            'plan_org_expenses',
            'data.donor_id',
            'data.is_procuratory',
            'data.is_agreement_investor',
            'data.is_agreement_donor',
            'plan_date_finish',
          ])
        "
        :values="data"
        :errors="errors"
        :config="{ dense: true, readonly, hideDetails: 'auto' }"
        @validate="validate($event)"
      />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :disabled="readonly" class="pr-2"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, libChecks } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, libChecks, getAccess],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects.form,
      removeDialogShow: false,
      suEditShow: false,
      removeTitle: "Вы действительно хотите удалить этот объект?",
      oModel: {},
      submitKey: false,
    };
  },
  computed: {
    readonly() {
      if (!this.id) return false;
      return !this.getAccess("object.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
    model() {
      let res = [];
      for (const el of this.m) {
        if (
          [
            "credit_sum",
            "data.investor_share",
            "plan_sell_price",
            "plan_expenses",
            "credit_first_payment",
            "plan_org_expenses",
            "data.doc_buy_price",
            "data.doc_sell_price",
            "data.donor_id",
            "data.is_procuratory",
            "data.is_agreement_investor",
            "data.is_agreement_donor",
            "plan_date_finish",
          ].includes(el.name)
        ) {
          res.push(el);
        }
      }
      return res;
    },
  },
  watch: {
    value(v) {
      if (v) {
        console.log("uuuuuuuuuuuuu", this.id);
        this.updateData(this.id);
      }
    },
  },
  created() {
    this.makeObjectModel();
  },
  methods: {
    afterFetchData() {},

    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    async submit() {
      if (this.saving) return;

      this.saving = true;

      let data = {};
      for (const name in this.data) {
        if (["id", "credit_sum", "data", "plan_sell_price", "plan_expenses", "credit_first_payment", "plan_org_expenses", "plan_date_finish"].includes(name)) {
          data[name] = this.data[name];
        }
      }
      if (await this.validateAll(data)) {
        await this.post(data, this.api);
        this.saving = false;
        return true;
      }
      this.saving = false;
      return false;
    },
  },
};
</script>
